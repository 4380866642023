var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: `key_${_vm.key}` },
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "mr-3",
                      staticStyle: { "font-size": "1.6rem" },
                    },
                    [_c("i", { staticClass: "flaticon2-calendar-2" })]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "left-buttons",
              fn: function () {
                return [_c("MarkingPeriodSubHeaderMenu")]
              },
              proxy: true,
            },
            _vm.selectedMarkingPeriod
              ? {
                  key: "buttons",
                  fn: function () {
                    return [
                      _vm.markingPeriod.markingPeriod != "1"
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-clean btn-clean-danger mr-3 btn-bold btn-sm",
                              on: { click: _vm.deleteMarkingPeriod },
                            },
                            [
                              _vm._v(
                                " Remove MP " +
                                  _vm._s(
                                    _vm.selectedMarkingPeriod.markingPeriod
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-bold",
                          class: [
                            _vm.isDirty
                              ? "btn-primary"
                              : "btn-clean btn-clean-success",
                          ],
                          on: { click: _vm.saveMarkingPeriods },
                        },
                        [
                          _vm._v(
                            " Save MP " +
                              _vm._s(_vm.selectedMarkingPeriod.markingPeriod) +
                              " "
                          ),
                          _vm.isDirty ? [_vm._v(" Changes ")] : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.selectedMarkingPeriod
        ? _c(
            "div",
            {
              staticClass:
                "kt-container kt-grid__item kt-grid__item--fluid mb-3",
            },
            [
              _vm.markingPeriod.deleted
                ? _c("div", { staticClass: "kt-portlet pb-5" }, [
                    _vm.markingPeriod.deleted
                      ? _c("div", { staticClass: "kt-portlet__foot" }, [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger",
                                attrs: { type: "button" },
                                on: { click: _vm.restore },
                              },
                              [
                                _vm._v(
                                  " Restore Marking Period " +
                                    _vm._s(
                                      _vm.selectedMarkingPeriod.markingPeriod
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              !_vm.markingPeriod.deleted
                ? _c("div", { staticClass: "kt-portlet pb-5" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _c("div", { staticClass: "kt-portlet__head-label" }, [
                        _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" Marking Period "),
                        ]),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "nav nav-pills nav-pills-sm nav-sm py-0 px-3 my-0",
                            attrs: { role: "tablist" },
                          },
                          _vm._l(_vm.markingPeriods, function (mp, idx) {
                            return _c(
                              "li",
                              {
                                key: `mp_${mp.markingPeriod}`,
                                staticClass: "nav-item",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    class: {
                                      active: idx === _vm.selectedIndex,
                                    },
                                    attrs: { "data-toggle": "tab", href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.selectMarkingPeriodAtIndex(
                                          idx
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(mp.markingPeriod) + " ")]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                        _c("div", { staticClass: "kt-portlet__head-actions" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-clean btn-sm btn-clean-success",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.addMarkingPeriod.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "la la-plus" }),
                              _vm._v(" Add MP "),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-portlet__body px-5" }, [
                      _c("form", { staticClass: "kt-form" }, [
                        _c("label", { staticClass: "kt-font-lg pb-2" }, [
                          _vm._v(" Marking Period Dates "),
                        ]),
                        _vm._m(0),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-5 col-lg-4" },
                            [
                              _c("label", [_vm._v("Start Date")]),
                              _c("b-form-datepicker", {
                                staticClass: "form-control kt-input",
                                attrs: {
                                  "date-format-options": {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  },
                                },
                                model: {
                                  value: _vm.markingPeriod.markingPeriodStart,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.markingPeriod,
                                      "markingPeriodStart",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "markingPeriod.markingPeriodStart",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-5 col-lg-4" },
                            [
                              _c("label", [_vm._v("End Date")]),
                              _c("b-form-datepicker", {
                                staticClass: "form-control kt-input",
                                attrs: {
                                  "date-format-options": {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  },
                                },
                                model: {
                                  value: _vm.markingPeriod.markingPeriodEnd,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.markingPeriod,
                                      "markingPeriodEnd",
                                      $$v
                                    )
                                  },
                                  expression: "markingPeriod.markingPeriodEnd",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.markingPeriod.markingPeriod !== "1"
                            ? _c("div", { staticClass: "col-md-2 col-lg-4" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "text-nowrap text-truncate d-block w-100",
                                  },
                                  [_vm._v("Cumulative")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "kt-switch kt-switch--outline kt-switch--icon kt-switch--success sg-switch",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticStyle: { "margin-bottom": "2px" },
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.markingPeriod.cumulative,
                                              expression:
                                                "markingPeriod.cumulative",
                                            },
                                          ],
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.markingPeriod.cumulative
                                            )
                                              ? _vm._i(
                                                  _vm.markingPeriod.cumulative,
                                                  null
                                                ) > -1
                                              : _vm.markingPeriod.cumulative,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a =
                                                  _vm.markingPeriod.cumulative,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.markingPeriod,
                                                      "cumulative",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.markingPeriod,
                                                      "cumulative",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.markingPeriod,
                                                  "cumulative",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _c("span"),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm.markingPeriod.markingPeriod !== "1"
                          ? _c("div", { staticClass: "text-muted pt-3" }, [
                              _vm._v(
                                " Cumulative marking periods include all assignments from previous marking periods when calculating averages. "
                              ),
                            ])
                          : _vm._e(),
                        _c("div", {
                          staticClass:
                            "kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit",
                        }),
                        _c("label", { staticClass: "kt-font-lg pb-2" }, [
                          _vm._v(" Report Card Grading "),
                          !_vm.uploadExists
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "ml-3 kt-font-danger kt-font-bold",
                                },
                                [_vm._v(" Excel upload needed to enable ")]
                              )
                            : _vm._e(),
                        ]),
                        _vm._m(1),
                        _c("div", { staticClass: "form-group row pt-3" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "media" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "px-4 mr-4",
                                  staticStyle: { "min-width": "140px" },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "d-block w-100 pt-4" },
                                    [_vm._v("Enabled")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-switch kt-switch--outline kt-switch--icon kt-switch--primary sg-switch",
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "2px",
                                          },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.markingPeriod
                                                    .starsGradingEnabled,
                                                expression:
                                                  "markingPeriod.starsGradingEnabled",
                                              },
                                            ],
                                            attrs: {
                                              disabled: !_vm.uploadExists,
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.markingPeriod
                                                  .starsGradingEnabled
                                              )
                                                ? _vm._i(
                                                    _vm.markingPeriod
                                                      .starsGradingEnabled,
                                                    null
                                                  ) > -1
                                                : _vm.markingPeriod
                                                    .starsGradingEnabled,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.markingPeriod
                                                      .starsGradingEnabled,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.markingPeriod,
                                                        "starsGradingEnabled",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.markingPeriod,
                                                        "starsGradingEnabled",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.markingPeriod,
                                                    "starsGradingEnabled",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _c("span"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "media-body" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("label", [
                                        _vm._v("Submissions Start Date"),
                                      ]),
                                      _c("b-form-datepicker", {
                                        staticClass: "form-control kt-input",
                                        attrs: {
                                          disabled:
                                            !_vm.uploadExists ||
                                            !_vm.markingPeriod
                                              .starsGradingEnabled,
                                          "date-format-options": {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.markingPeriod.submissionStart,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "submissionStart",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "markingPeriod.submissionStart",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("label", [_vm._v("Start Time")]),
                                      _c("b-form-timepicker", {
                                        staticClass: "form-control kt-input",
                                        class: `!markingPeriod.submissionStartTime ? 'sg-field-error' : ''}`,
                                        attrs: {
                                          disabled:
                                            !_vm.uploadExists ||
                                            !_vm.markingPeriod
                                              .starsGradingEnabled,
                                        },
                                        model: {
                                          value:
                                            _vm.markingPeriod
                                              .submissionStartTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "submissionStartTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "markingPeriod.submissionStartTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("div", { staticClass: "row pt-3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("label", [
                                        _vm._v("Submissions End Date"),
                                      ]),
                                      _c("b-form-datepicker", {
                                        staticClass: "form-control kt-input",
                                        attrs: {
                                          disabled:
                                            !_vm.uploadExists ||
                                            !_vm.markingPeriod
                                              .starsGradingEnabled,
                                          "date-format-options": {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.markingPeriod.submissionEnd,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "submissionEnd",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "markingPeriod.submissionEnd",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("label", [_vm._v("End Time")]),
                                      _c("b-form-timepicker", {
                                        staticClass: "form-control kt-input",
                                        attrs: {
                                          disabled:
                                            !_vm.uploadExists ||
                                            !_vm.markingPeriod
                                              .starsGradingEnabled,
                                        },
                                        model: {
                                          value:
                                            _vm.markingPeriod.submissionEndTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "submissionEndTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "markingPeriod.submissionEndTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group row pt-3" }, [
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.exam && _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Exam Score Entry "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.exam,
                                      expression: "markingPeriod.exam",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_exam_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.exam
                                    )
                                      ? _vm._i(_vm.markingPeriod.exam, null) >
                                        -1
                                      : _vm.markingPeriod.exam,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.exam,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "exam",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "exam",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.markingPeriod, "exam", $$c)
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.conduct && _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Conduct Entry "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.conduct,
                                      expression: "markingPeriod.conduct",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_conduct_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.conduct
                                    )
                                      ? _vm._i(
                                          _vm.markingPeriod.conduct,
                                          null
                                        ) > -1
                                      : _vm.markingPeriod.conduct,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.conduct,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "conduct",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "conduct",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.markingPeriod,
                                          "conduct",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.level && _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Level Entry "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.level,
                                      expression: "markingPeriod.level",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_level_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.level
                                    )
                                      ? _vm._i(_vm.markingPeriod.level, null) >
                                        -1
                                      : _vm.markingPeriod.level,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.level,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "level",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "level",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.markingPeriod,
                                          "level",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.final && _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Mark as Final "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.final,
                                      expression: "markingPeriod.final",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_final_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.final
                                    )
                                      ? _vm._i(_vm.markingPeriod.final, null) >
                                        -1
                                      : _vm.markingPeriod.final,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.final,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "final",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "final",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.markingPeriod,
                                          "final",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.narrative &&
                                  _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Narrative Entry "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.narrative,
                                      expression: "markingPeriod.narrative",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_narrative_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.narrative
                                    )
                                      ? _vm._i(
                                          _vm.markingPeriod.narrative,
                                          null
                                        ) > -1
                                      : _vm.markingPeriod.narrative,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.narrative,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "narrative",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "narrative",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.markingPeriod,
                                          "narrative",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.commentOne &&
                                  _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Comment One Entry "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.commentOne,
                                      expression: "markingPeriod.commentOne",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_comment_one_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.commentOne
                                    )
                                      ? _vm._i(
                                          _vm.markingPeriod.commentOne,
                                          null
                                        ) > -1
                                      : _vm.markingPeriod.commentOne,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.commentOne,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "commentOne",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "commentOne",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.markingPeriod,
                                          "commentOne",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.commentTwo &&
                                  _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Comment Two Entry "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.commentTwo,
                                      expression: "markingPeriod.commentTwo",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_comment_two_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.commentTwo
                                    )
                                      ? _vm._i(
                                          _vm.markingPeriod.commentTwo,
                                          null
                                        ) > -1
                                      : _vm.markingPeriod.commentTwo,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.commentTwo,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "commentTwo",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "commentTwo",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.markingPeriod,
                                          "commentTwo",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "col-4 pb-2 pl-1" }, [
                            _c(
                              "label",
                              {
                                staticClass: "kt-checkbox kt-checkbox--bold",
                                class: [
                                  _vm.markingPeriod.commentThree &&
                                  _vm.uploadExists
                                    ? "kt-checkbox--primary"
                                    : "",
                                  !_vm.uploadExists
                                    ? "kt-checkbox--disabled"
                                    : "",
                                ],
                              },
                              [
                                _vm._v(" Allow Comment Three Entry "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.markingPeriod.commentThree,
                                      expression: "markingPeriod.commentThree",
                                    },
                                  ],
                                  attrs: {
                                    id:
                                      _vm.markingPeriod.markingPeriod +
                                      "_comment_three_check",
                                    disabled:
                                      !_vm.uploadExists ||
                                      !_vm.markingPeriod.starsGradingEnabled,
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.markingPeriod.commentThree
                                    )
                                      ? _vm._i(
                                          _vm.markingPeriod.commentThree,
                                          null
                                        ) > -1
                                      : _vm.markingPeriod.commentThree,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.markingPeriod.commentThree,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "commentThree",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.markingPeriod,
                                              "commentThree",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.markingPeriod,
                                          "commentThree",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _c("span"),
                              ]
                            ),
                          ]),
                        ]),
                        _c("div", {
                          staticClass:
                            "kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit",
                        }),
                        _c("label", { staticClass: "kt-font-lg pb-2" }, [
                          _vm._v(" Grade Visibility "),
                        ]),
                        _vm._m(2),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6 col-lg-4" },
                            [
                              _c("label", [_vm._v("Release Date:")]),
                              _c("b-form-datepicker", {
                                staticClass: "form-control kt-input",
                                attrs: {
                                  "date-format-options": {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  },
                                },
                                model: {
                                  value: _vm.markingPeriod.gradesVisibleDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.markingPeriod,
                                      "gradesVisibleDate",
                                      $$v
                                    )
                                  },
                                  expression: "markingPeriod.gradesVisibleDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group form-group-last" }, [
      _c(
        "div",
        { staticClass: "alert alert-secondary", attrs: { role: "alert" } },
        [
          _c("div", { staticClass: "alert-icon" }, [
            _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
          ]),
          _c("div", { staticClass: "alert-text" }, [
            _vm._v(
              " Marking period dates are used as a reference for teachers and students. Assignments are not automatically placed based on these dates, they are manually assigned by the teacher. "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group form-group-last" }, [
      _c(
        "div",
        { staticClass: "alert alert-secondary", attrs: { role: "alert" } },
        [
          _c("div", { staticClass: "alert-icon" }, [
            _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
          ]),
          _c("div", { staticClass: "alert-text" }, [
            _vm._v(
              " Submissions start and end dates define the window during which teachers can submit report card grades. "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group form-group-last" }, [
      _c(
        "div",
        { staticClass: "alert alert-secondary", attrs: { role: "alert" } },
        [
          _c("div", { staticClass: "alert-icon" }, [
            _c("i", { staticClass: "flaticon-warning kt-font-brand" }),
          ]),
          _c("div", { staticClass: "alert-text" }, [
            _vm._v(
              " Grades will be hidden from Student Portfolio's until the date and time specified. If left blank, grades will be released 2 weeks after the marking period ends. "
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }