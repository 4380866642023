<template>
<div :key="`key_${key}`">
    <GeneralSubHeader>
        <template #title>
            <span
                class="mr-3"
                style="font-size: 1.6rem;"
            >
                <i class="flaticon2-calendar-2" />
            </span>
        </template>
        <template #left-buttons>
            <MarkingPeriodSubHeaderMenu />
        </template>
        <template v-if="selectedMarkingPeriod" #buttons>
            <button
                v-if="markingPeriod.markingPeriod != '1'"
                class="btn btn-clean btn-clean-danger mr-3 btn-bold btn-sm"
                @click="deleteMarkingPeriod"
            >
                Remove MP {{ selectedMarkingPeriod.markingPeriod }}
            </button>
            <button
                class="btn btn-sm btn-bold"
                :class="[isDirty ? 'btn-primary' : 'btn-clean btn-clean-success']"
                @click="saveMarkingPeriods"
            >
                Save MP {{ selectedMarkingPeriod.markingPeriod }}
                <template v-if="isDirty">
                    Changes
                </template>
            </button>
        </template>
    </GeneralSubHeader>

    <div
        v-if="selectedMarkingPeriod"
        class="kt-container kt-grid__item kt-grid__item--fluid mb-3"
    >
        <div
            v-if="markingPeriod.deleted"
            class="kt-portlet pb-5"
        >
            <div
                v-if="markingPeriod.deleted"
                class="kt-portlet__foot"
            >
                <div>
                    <button
                        type="button"
                        class="btn btn-danger"
                        @click="restore"
                    >
                        Restore Marking Period {{ selectedMarkingPeriod.markingPeriod }}
                    </button>
                </div>
            </div>
        </div>

        <div
            v-if="!markingPeriod.deleted"
            class="kt-portlet pb-5"
        >
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Marking Period
                    </h3>
                    <ul
                        class="nav nav-pills nav-pills-sm nav-sm py-0 px-3 my-0"
                        role="tablist"
                    >
                        <li
                            v-for="(mp, idx) in markingPeriods"
                            :key="`mp_${mp.markingPeriod}`"
                            class="nav-item"
                        >
                            <a
                                class="nav-link"
                                :class="{
                                    active: idx === selectedIndex
                                }"
                                data-toggle="tab"
                                href="#"
                                @click.stop.prevent="selectMarkingPeriodAtIndex(idx)"
                            >
                                {{ mp.markingPeriod }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-actions">
                        <a
                            href="#"
                            class="btn btn-clean btn-sm btn-clean-success"
                            @click.stop.prevent="addMarkingPeriod"
                        >
                            <i class="la la-plus" />
                            Add MP
                        </a>
                    </div>
                </div>
            </div>

            <div class="kt-portlet__body px-5">
                <form class="kt-form">
                    <label class="kt-font-lg pb-2">
                        Marking Period Dates
                    </label>

                    <div class="form-group form-group-last">
                        <div
                            class="alert alert-secondary"
                            role="alert"
                        >
                            <div class="alert-icon">
                                <i class="flaticon-warning kt-font-brand" />
                            </div>
                            <div class="alert-text">
                                Marking period dates are used as a reference for teachers and students.
                                Assignments are not automatically placed based on these dates, they are manually assigned by the teacher.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-5 col-lg-4">
                            <label>Start Date</label>
                            <b-form-datepicker
                                v-model="markingPeriod.markingPeriodStart"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                class="form-control kt-input"
                            />
                        </div>
                        <div class="col-md-5 col-lg-4">
                            <label>End Date</label>
                            <b-form-datepicker
                                v-model="markingPeriod.markingPeriodEnd"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                class="form-control kt-input"
                            />
                        </div>
                        <div
                            v-if="markingPeriod.markingPeriod !== '1'"
                            class="col-md-2 col-lg-4"
                        >
                            <label class="text-nowrap text-truncate d-block w-100">Cumulative</label>
                            <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success sg-switch">
                                <label style="margin-bottom: 2px;">
                                    <input
                                        v-model="markingPeriod.cumulative"
                                        type="checkbox"
                                    >
                                    <span />
                                </label>
                            </span>
                        </div>
                    </div>

                    <div
                        v-if="markingPeriod.markingPeriod !== '1'"
                        class="text-muted pt-3"
                    >
                        Cumulative marking periods include all assignments from previous marking periods when calculating averages.
                    </div>

                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit" />

                    <label class="kt-font-lg pb-2">
                        Report Card Grading
                        <span
                            v-if="!uploadExists"
                            class="ml-3 kt-font-danger kt-font-bold"
                        >
                            Excel upload needed to enable
                        </span>
                    </label>

                    <div class="form-group form-group-last">
                        <div
                            class="alert alert-secondary"
                            role="alert"
                        >
                            <div class="alert-icon">
                                <i class="flaticon-warning kt-font-brand" />
                            </div>
                            <div class="alert-text">
                                Submissions start and end dates define the window during
                                which teachers can submit report card grades.
                            </div>
                        </div>
                    </div>

                    <div class="form-group row pt-3">
                        <div class="col-12">
                            <div class="media">
                                <div
                                    class="px-4 mr-4"
                                    style="min-width: 140px;"
                                >
                                    <label class="d-block w-100 pt-4">Enabled</label>
                                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--primary sg-switch">
                                        <label style="margin-bottom: 2px;">
                                            <input
                                                v-model="markingPeriod.starsGradingEnabled"
                                                :disabled="!uploadExists"
                                                type="checkbox"
                                            >
                                            <span />
                                        </label>
                                    </span>
                                </div>

                                <div class="media-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Submissions Start Date</label>
                                            <b-form-datepicker
                                                v-model="markingPeriod.submissionStart"
                                                :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                class="form-control kt-input"
                                            />
                                        </div>
                                        <div class="col-md-6">
                                            <label>Start Time</label>
                                            <b-form-timepicker
                                                v-model="markingPeriod.submissionStartTime"
                                                :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                                class="form-control kt-input"
                                                :class="`!markingPeriod.submissionStartTime ? 'sg-field-error' : ''}`"
                                            />
                                        </div>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-md-6">
                                            <label>Submissions End Date</label>
                                            <b-form-datepicker
                                                v-model="markingPeriod.submissionEnd"
                                                :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                class="form-control kt-input"
                                            />
                                        </div>
                                        <div class="col-md-6">
                                            <label>End Time</label>
                                            <b-form-timepicker
                                                v-model="markingPeriod.submissionEndTime"
                                                :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                                class="form-control kt-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row pt-3">
                        <div class="col-4 pb-2 pl-1">
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.exam && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Exam Score Entry
                                <input
                                    :id="markingPeriod.markingPeriod +'_exam_check'"
                                    v-model="markingPeriod.exam"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                        <div class="col-4 pb-2 pl-1">
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.conduct && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Conduct Entry
                                <input
                                    :id="markingPeriod.markingPeriod +'_conduct_check'"
                                    v-model="markingPeriod.conduct"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                        <div

                            class="col-4 pb-2 pl-1"
                        >
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.level && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Level Entry
                                <input
                                    :id="markingPeriod.markingPeriod +'_level_check'"
                                    v-model="markingPeriod.level"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                        <div

                            class="col-4 pb-2 pl-1"
                        >
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.final && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Mark as Final
                                <input
                                    :id="markingPeriod.markingPeriod +'_final_check'"
                                    v-model="markingPeriod.final"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                        <div class="col-4 pb-2 pl-1">
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.narrative && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Narrative Entry
                                <input
                                    :id="markingPeriod.markingPeriod +'_narrative_check'"
                                    v-model="markingPeriod.narrative"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                        <div class="col-4 pb-2 pl-1">
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.commentOne && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Comment One Entry
                                <input
                                    :id="markingPeriod.markingPeriod +'_comment_one_check'"
                                    v-model="markingPeriod.commentOne"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                        <div class="col-4 pb-2 pl-1">
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.commentTwo && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Comment Two Entry
                                <input
                                    :id="markingPeriod.markingPeriod +'_comment_two_check'"
                                    v-model="markingPeriod.commentTwo"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                        <div class="col-4 pb-2 pl-1">
                            <label
                                class="kt-checkbox kt-checkbox--bold"
                                :class="[
                                    markingPeriod.commentThree && uploadExists ? 'kt-checkbox--primary' : '',
                                    !uploadExists ? 'kt-checkbox--disabled' : '',
                                ]"
                            >
                                Allow Comment Three Entry
                                <input
                                    :id="markingPeriod.markingPeriod +'_comment_three_check'"
                                    v-model="markingPeriod.commentThree"
                                    :disabled="!uploadExists || !markingPeriod.starsGradingEnabled"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </div>
                    </div>

                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit" />

                    <label class="kt-font-lg pb-2">
                        Grade Visibility
                    </label>

                    <div class="form-group form-group-last">
                        <div
                            class="alert alert-secondary"
                            role="alert"
                        >
                            <div class="alert-icon">
                                <i class="flaticon-warning kt-font-brand" />
                            </div>
                            <div class="alert-text">
                                Grades will be hidden from Student Portfolio's until the date and time specified.
                                If left blank, grades will be released 2 weeks after the marking period ends.
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-lg-4">
                            <label>Release Date:</label>
                            <b-form-datepicker
                                v-model="markingPeriod.gradesVisibleDate"
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                class="form-control kt-input"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import moment from 'moment';
import * as net from '../network';
import Types from '../store/Types';
import dataSystemMixins from '../store/mixins/dataSystemMixins';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import MarkingPeriodSubHeaderMenu from '../components/MarkingPeriodSubHeaderMenu.vue';

export default Vue.extend({
    name: 'MarkingPeriods',
    components: {
        MarkingPeriodSubHeaderMenu,
        GeneralSubHeader,
    },
    mixins: [dataSystemMixins],
    data() {
        return {
            key: 0,
            isDirty: false,
            selectedIndex: 0,
            markingPeriods: [],
            markingPeriod: {
                markingPeriod: null,
                starsGradingEnabled: false,
                markingPeriodStart: null,
                markingPeriodEnd: null,
                submissionStart: null,
                submissionEnd: null,
                submissionStartTime: null,
                submissionEndTime: null,
                exam: false,
                conduct: false,
                level: false,
                final: false,
                narrative: false,
                commentOne: true,
                commentTwo: true,
                commentThree: true,
                gradesVisibleDate: null,
                cumulative: false,
                deleted: false,
            },
            saving: false,
        };
    },
    computed: {
        uploadExists() {
            if (this.$store.state.user.school.externalSchoolId == '00X000') return true;
            const mps = this.$_dataSystemMixins_getMarkingPeriodsWithUpload;
            const { selectedMarkingPeriod } = this;
            return Boolean(mps.find((mp) => mp.bucketPath && mp.markingPeriod == selectedMarkingPeriod.markingPeriod));
        },
        user() {
            return this.$store.state.user;
        },
        schoolTerm() {
            return this.user.school;
        },
        schoolTermId() {
            return this.user.school.schoolTermId;
        },
        dataSystems() {
            return this.$store.state.database.dataSystems;
        },
        isStarsAdmin() {
            const v = this;
            const isStarsClassroom = v.dataSystems.includes('STARS Classroom');
            return !isStarsClassroom;
        },
        stateMarkingPeriods() {
            return this.$store.state.database.markingPeriods;
        },
        schoolTerms() {
            return this.$store.state.database.schoolTerms;
        },
        selectedMarkingPeriod() {
            const { markingPeriods, selectedIndex } = this;
            if (selectedIndex === null || selectedIndex === -1) return null;
            return markingPeriods[selectedIndex];
        },
    },
    watch: {
        markingPeriod: {
            deep: true,
            handler() {
                const v = this;
                v.$nextTick(() => {
                    v.isDirty = true;
                });
            },
        },
    },
    mounted() {
        const v = this;
        v.$store.dispatch(Types.actions.REFRESH_MARKING_PERIODS, (err) => {
            if (err) return v.showError(err);
            this.populate();
            v.key += 1;
        });
    },
    methods: {
        selectMarkingPeriodAtIndex(idx) {
            this.selectedIndex = idx;
            this.copyToMutableState();
        },
        addMarkingPeriod() {
            const isValid = this.validateMarkingPeriod();
            if (!isValid) return;

            // add to stack
            const [lastMarkingPeriod] = this.markingPeriods.slice(-1);
            const mp = { ...lastMarkingPeriod };
            if (!mp.markingPeriod) return;
            const numericMarkingPeriod = parseInt(mp.markingPeriod, 10);
            if (numericMarkingPeriod > 5) return;

            mp.markingPeriod = `${numericMarkingPeriod + 1}`;
            mp.markingPeriodStart = this.schoolTerm.termStart;
            mp.markingPeriodEnd = this.schoolTerm.termEnd;
            mp.submissionStart = this.schoolTerm.termStart;
            mp.submissionEnd = this.schoolTerm.termEnd;
            mp.submissionStartTime = null;
            mp.submissionEndTime = null;
            mp.deleted = false;

            this.markingPeriods.push(mp);

            this.saveMarkingPeriods();
        },
        populate() {
            const { markingPeriods } = this.$store.state.database;
            this.markingPeriods = markingPeriods.map((x) => ({ ...x }));
            const [first] = markingPeriods;
            if (!first) return;
            this.copyToMutableState();
        },
        copyToMutableState() {
            this.markingPeriod = { ...this.selectedMarkingPeriod };
            const v = this;
            setTimeout(() => {
                v.isDirty = false;
            }, 10);
        },
        validateMarkingPeriod() {
            const { markingPeriod, showError } = this;

            const markingPeriodStartMoment = moment(markingPeriod.markingPeriodStart, 'YYYY-MM-DD');
            if (!markingPeriodStartMoment.isValid()) {
                showError('Marking Period is missing a start date');
                return false;
            }

            const markingPeriodEndMoment = moment(markingPeriod.markingPeriodEnd, 'YYYY-MM-DD');
            if (!markingPeriodEndMoment.isValid()) {
                showError('Marking Period is missing a end date');
                return false;
            }
            if (markingPeriodStartMoment.isAfter(markingPeriodEndMoment)) {
                showError('Marking Period start date is after end date');
                return false;
            }

            const submissionStartMoment = moment(markingPeriod.submissionStart, 'YYYY-MM-DD');
            if (!submissionStartMoment.isValid()) {
                showError('Marking Period is missing a submission start date');
                return false;
            }

            const submissionEndMoment = moment(markingPeriod.submissionEnd, 'YYYY-MM-DD');
            if (!submissionEndMoment.isValid()) {
                showError('Marking Period is missing a submission end date');
                return false;
            }

            if (submissionStartMoment.isAfter(submissionEndMoment)) {
                showError('Marking Period submission start date is after submission end date');
                return false;
            }
            return true;
        },
        saveMarkingPeriods() {
            if (this.saving) return;
            const isValid = this.validateMarkingPeriod();
            if (!isValid) return;

            const markingPeriod = { ...this.markingPeriod };
            if (!this.uploadExists) {
                markingPeriod.starsGradingEnabled = false;
            }
            // markingPeriod.deleted = false;
            this.markingPeriods = this.markingPeriods.map((mp) => {
                if (mp.markingPeriod == markingPeriod.markingPeriod) {
                    return { ...markingPeriod };
                }
                return { ...mp };
            });
            const { markingPeriods } = this;
            this.post(markingPeriods);
        },
        deleteMarkingPeriod() {
            if (this.saving) return;
            const isValid = this.validateMarkingPeriod();
            if (!isValid) return;
            this.markingPeriod.deleted = true;
            const markingPeriod = { ...this.markingPeriod };
            this.markingPeriods = this.markingPeriods.map((mp) => {
                if (mp.markingPeriod == markingPeriod.markingPeriod) {
                    return { ...markingPeriod };
                }
                return { ...mp };
            });
            const { markingPeriods } = this;
            this.post(markingPeriods);
        },
        restore() {
            if (this.saving) return;
            const isValid = this.validateMarkingPeriod();
            if (!isValid) return;
            this.markingPeriod.deleted = false;
            const markingPeriod = { ...this.markingPeriod };
            this.markingPeriods = this.markingPeriods.map((mp) => {
                if (mp.markingPeriod == markingPeriod.markingPeriod) {
                    return { ...markingPeriod };
                }
                return { ...mp };
            });
            const { markingPeriods } = this;
            this.post(markingPeriods);
        },
        post(markingPeriods) {
            const { schoolId, schoolTermId } = this.user.school;
            const { commit } = this.$store;
            const params = { url: { schoolId, schoolTermId }, body: { markingPeriods } };
            this.saving = true;
            const v = this;
            net.markingPeriods.upsertMarkingPeriods(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                commit(Types.mutations.SET_DB_MARKING_PERIODS, res.markingPeriods);
                v.key += 1;
                v.showAlert('Marking Periods saved successfully', 'Saved', 'success');

                v.copyToMutableState();
            });
        },
    },
});

</script>
<style scoped>
td.title {
    padding-top: 18px;
}
td.mp-name {
    white-space: nowrap;
    max-width: 10rem;
}
td.sg-buttons {
    display: flex;
    justify-content: space-around;
}
.calendar-popover {
    max-width: 100%;
    width: 400px;
}
.sg-field-error {
    border-color: red;
}
.sg-title-button {
    line-height: 19.5px;
}
span.sg-portlet-sub-header {
    padding: 16px 0;
    font-size: 1.2em;
    font-weight: 600;
    display: block;
}
.sg-portlet-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 25px;
    height: 59px;
    border-top: 1px solid #ebedf2;
}
.sg-top-form {
    display: flex;
}
.sg-h4 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 1.1em;
}
</style>
